.statistic-modal__header {
  @extend %between;
  align-items: center;
  height: 57px;
  padding: 20px 40px 13px;
  border-bottom: 1px solid $gray;
  margin-bottom: 26px;
}

.statistic-modal__indicator {
  display: flex;
  justify-content: center;
  justify-items: center;
  width: 600px;
}

.statistic-modal__title {
  font-size: 15px;
  font-weight: bold;
  color: $white;
  align-self: baseline;
}

.statistic-modal__close-icon {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.statistic-modal-wrapper-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.statistic-modal-curves__content {
  width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: flex-end;
}

.wrapper-multiselect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  background: purple;
}

.container-multiselect {
  display: flex;
  flex-direction: column;
  width: 600px;
}

.statistic-modal {
  &__top-bottom-container {
    width: 500;
    padding-top: 2rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__top-bottom-label {
    position: absolute;
    top: 10px;
    left: 0px;
    color: $white;
  }
}

.statistic-modal__steps {
  width: 90%;
}

.statistic-modal-curves__btn {
  display: flex;
  justify-content: flex-end;
  margin-right: 60px;
  margin-top: 60px;
  margin-bottom: 30px;
}

.statistic-modal-wrapper__indicator {
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-bottom: 60px;
}

.statistic-modal__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.statistic-modal__label {
  font-weight: 700;
  font-size: 16px;
  color: $white;
}

.statistic-modal-curves
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #fff;
}

.statistic-modal-curves
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item:last-child
  .ant-steps-item-title {
  color: #fff;
}

.statistic-modal-curves
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item:first-child
  .ant-steps-item-title {
  color: #fff;
}

.statistic-modal-curve__top-bottom-container {
  width: 100%;
  padding-top: 4rem;
  position: relative;
}

.title-curve-configuration {
  margin-bottom: 20px;
  color: #fff;
}

.wrapper-collapse-panel {
  margin-top: 40px;
}

.statistic-modal__steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: $white;
}

.statistic-modal__steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: $white;
}

.statistic-modal__btn-actions-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 400px;
}

.statistic-modal__btn-actions {
  width: 26%;
  height: 50px;
  padding-top: 20px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.multiselect___select
  .ant-select
  .ant-select-open
  .ant-select-focused
  .ant-select-enabled {
  background: #000;
}

.btn-select___wrapper {
  display: flex;
  align-self: flex-start;
  margin-top: 20px;
}

.container-conflict-error {
  margin-bottom: 30px;
}

.container-alert-curves {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.alert-curves {
  max-width: 800px;
}

.ant-slider-rail {
  background-color: hsl(232, 26%, 17%);
  
}

.ant-slider-rail {
  background-color: #212438; 
}

.ant-slider-track {
  background-color: #4F62C5;
}

.ant-slider-handle {
  border: 2px solid #233BB6;
}

.ant-slider-handle:focus,
.ant-slider-handle:hover {
  border-color: #4F62C5;
  box-shadow: 0 0 5px #4F62C5; 
}

.ant-btn-primary {
  background-color: #233BB6;
  border-color: #233BB6;
  border-radius: 6px;
}

.statistic-modal__actions .ant-btn-primary {
  margin-top: 10px;
}

.ant-btn-primary:hover {
  background-color: #233BB6;
  border-color: #fff;
  color: #fff;
}

.statistic-modal__slider-inputs-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.statistic-modal__slider-inputs {
  margin-top: 10px;
}

.ant-input-number {
  background-color: transparent !important;
  border: 1px solid #525775;
  color: #fff;
  border-radius: 6px;
  width: 130px;
}

.ant-input-number:hover {
  border: 2px solid #525775;
}

.ant-input-number-handler-wrap {
  background-color: transparent !important;
  border-color: #525775;
  color: #fff !important;
}

.anticon {
  color: #fff;
}

.ant-btn-default {
  background-color: transparent;
  border-color: #525775;
  color: #fff;
  border-radius: 6px;
  margin-top: 10px;
}

.ant-btn-default:hover {
  background-color: #202438;
  border: 1px solid #A9AEC7;
  color: #fff;
}

