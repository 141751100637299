.multiselect__select
  .ant-select-selection--multiple
  .ant-select-selection__choice {
  background: #3f3f46;
  border-radius: 6px;
  border: 0.5px solid #3f3f46;
  color: #ffffff;
}

.multiselect__select .ant-select-selection {
  background: transparent;
  border: 1px solid #3f3f46;
  border-radius: 8px;
  padding: 4px;
}

.multiselect__select
  .ant-select-selection--multiple
  .ant-select-selection__choice__remove {
  color: $white;
}

.multiselect__title {
  color: $white;
  font-size: 14px;
  margin-left: 90px;
}

.multiselect__select .ant-select {
  width: 100% !important;
}

.ant-select-dropdown {
  background: #27272a;
  padding: 5px;
}

.ant-select-dropdown-menu-item {
  color: $white;
  background: #27272a !important;
}

.ant-select-dropdown-menu-item:hover {
  background: #091133 !important;
  border-radius: 6px;
  border: 0.5px solid #091133;
}

.ant-select-dropdown-menu-item-selected {
  background: #091133 !important;
  border-radius: 6px;
  border: 0.5px solid #091133;
}
