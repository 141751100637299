.container-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 400px;
  gap: 10px;

  span {
    color: #FAFBFF;
    font-size: 18px;
  }
}

.circular-progress-molar {
  color: #1a90ff;
}
