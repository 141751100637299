.export-modal-container {
  width: 700px;
  height: 50%;
}

.head-export-modal {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #616D72;
  padding: 20px 32px 24px 32px;

  span {
    color: #F5F5F5;
    font-size: 24px;
  }

  img {
    width: 15px;

    &:hover {
      cursor: pointer;
    }
  }
}

.wrapper-body-export-modal {
  margin: 24px 32px;
}

.body-export-modal {
  height: 350px;
  background-color: #333333;
  margin-top: 20px;
  padding: 24px 0px 24px 14px;
  overflow: overlay;

  .ant-tree li .ant-tree-node-content-wrapper:hover {
    background-color: transparent !important;
  }

  .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: transparent !important;
  }

}

.tree-export-modal {
  color: #FFFFFF;

  .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg  {
    font-size: 18px !important;
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #27A9D9;
    border-color: #27A9D9;
  }

  .ant-tree-checkbox-checked::after {
    border: 1px solid #0DA4D9;
  }

  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
    background-color: #27A9D9;
    border-color: #27A9D9;

    &::after {
      background-color: transparent !important;
      position: absolute;
      display: table;
      top: 50%;
      left: 22%;
      width: 5.71428571px;
      height: 9.14285714px;
      border: 2px solid #fff;
      border-left: 0;
      border-top: 0;
      opacity: 1;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: '';
    }
  }

  .ant-tree-node-content-wrapper {
    color: #FFFFFF !important;
    font-size: 16px;
  }
  
}

.btn-wrapper-export-modal {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.btn-ok-export-modal, .btn-export-modal {
  width: 89px;
  height: 32px;
  background-color: #007BFF;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.btn-export-modal {
  margin-left: 10px;
}
