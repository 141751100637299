.cuttoff-load-container {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex-direction: column;
}

.wrapper-form-cutoffs {
  input {
    padding: 0.5rem;
    border: 1px solid #A3A8C7;
    padding: 5px;
    width: 100%;
    border-radius: 5px;
    background: transparent;
    color: #fff;
  }

  .button-cutoff-viewer {
    width: 165px;
    height: 32px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: none;
    color: #fff;
    background-color: #007BFF;
    outline: none;
    cursor: pointer;

    .img-cutoff-viewer {
      margin-right: 5px;
    }
  }

  .wrapper-cutoff-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }

  .suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
  }

  .suggestions li {
    padding: 0.5rem;
    background-color: transparent;
    color: #A3A8C7;;
  }

  .suggestion-active,
  .suggestions li:hover {
    background-color: transparent;
    color: #A3A8C7;
    cursor: pointer;
    font-weight: 700;
  }

  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }
}

.select-cut-offs {
  width: 8rem !important;
}

.main-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  border-radius: 12px;
  border: 1px solid #323752;
  background-color: transparent;
}

.wrapper-input-cutoffs {
  display: flex;
  flex-direction: column;
}

.wrapper-input-filters {
  width: 100%;
  padding: 8px;
  justify-content: space-evenly;
  align-items: center;
  background-color: transparent;
  flex-direction: row;
  &:not(:last-child){
    border-bottom: 1px solid #323752;
  }
}

.box-input-cutoffs {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.wrapper-logical-operator {
  width: 90%;
  margin: auto;
  margin-left: 20px;
  display: flex;
  justify-content: flex-start;
}

.box-checkbox {
  width: 32px;
}

.box-checkbox-header {
  width: 43px;
}


.info-head-form {
  &-1 {
    margin-left: 10px;
    width: 130px;
  }
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: transparent;
  border-bottom-color: #A3A8C7;
}

.wrapper-main-info {
  padding-bottom: 8px;
  padding-top: 8px;
  border-bottom: 2px solid #fff;
  width: 100%;
}

.wrapper-info {
  width: 463px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-form {
    width: 100%;
  }
}

.box-info-head {
  width: 150px;
  position: relative;
  color: #fff;
  &-1 {
    margin-left: 46px;
  }
  &-2 {
    margin-left: 32px;
  }
  &-3 {
    margin-left: 33px;
  }
}

.wrapper-main-form {
  justify-content: flex-start;
}

.box-and-or {
  margin-top: 10px;
}

.ant-checkbox-inner {
  background-color: transparent;
}

.ant-select-selection {
  background-color: transparent;
  color: #A3A8C7;
}

.ant-select-arrow {
  color: #A3A8C7;
}

.cutoff-save-button {
  width: 13%;
  background: #007bff;
  padding: 6px 9px;
  font-size: 0.9em;
  height: 32px;
  border: none;
  border-radius: 5px;
  color: #F5F5F5;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Poppins';
  cursor: pointer;
  outline: none;
}

.cutoff-clear-button {
  margin-right: 5px;
  width: auto;
  background-color: #F5F5F5;
  color: #45444f;
}
