.baseline-form {
  display: flex;
  align-items: center;
  margin-left: 40px;
  & .wrapper-settings-input-molar {
    margin-top: 0;
  }
}

.wrapper-baseline-input {
  width: 130px;
  height: 96px;
  margin-right: 5px;
  & input {
    width: 110px !important;
  }
}

.section-title {
  margin-left: 40px;
  margin-top: 10px;
  color: #fff;
  font-size: 18px;
  font-family: sans-serif;
}