.statistic-modal__header {
  @extend %between;
  align-items: center;
  height: 57px;
  padding: 20px 40px 13px;
  border-bottom: 1px solid $gray;
  margin-bottom: 26px;
}

.statistic-modal__title {
  font-size: 15px;
  font-weight: bold;
  color: $white;
  align-self: baseline;
}

.statistic-modal__close-icon {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.statistic-modal__content {
  width: 100%;
  height: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper-multiselect {
  width: 90%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.statistic-modal__steps {
  width: 90%;
}

.statistic-modal__steps
.ant-steps-item-process > 
.ant-steps-item-container > 
.ant-steps-item-content > 
.ant-steps-item-title {
  color: $white;
}

.statistic-modal__steps 
.ant-steps-item-wait > 
.ant-steps-item-container > 
.ant-steps-item-content > 
.ant-steps-item-title {
  color: $white;
}

.statistic-modal__btn-actions-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 400px;
}

.statistic-modal__btn-actions {
  width: 26%;
  height: 50px;
  padding-top: 20px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.multiselect___select 
.ant-select 
.ant-select-open
 .ant-select-focused 
 .ant-select-enabled {
  background: #000;
}

.btn-select___wrapper {
  display: flex;
  align-self: flex-start;
  margin-top: 20px;
}

.module-area {
  width: 540px;
  height: 45px;
  background: #484550;
  margin-top: 30px;
  color:#F5F5F5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  &__title, &__values {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px
  }

  &__icons {
    width: 50px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &__content {
    width: 200px;
    display: flex;
    justify-content: space-between;
  }

  &__edit, &__remove {
    width: 16px;
    height: 16px;
  }  
}
