.instructions-msg {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 568px;
  height: 160px;
  background: $dark6;
  opacity: 0.8;
  border: 1px solid $blue2;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: $fontRoboto;

  &__title {
    color: $gray4;
    font-size: 16px;
  }

  &__info {
    color: $white2;
    font-size: 14px;
    font-weight: normal;
  }

  &__btn {
    padding: 6px;
    width: 210px;
    background: $dark6;
    border: solid 1px $white;
    font-size: 14px;
    border-radius: 2px;
    margin-top: 12px;
    display: flex;
    justify-content: center;
    justify-items: center;
    cursor: pointer;
  }

  &__btn-text {
    color: $white;
    font-weight: normal;
    margin-left: 6px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  
  &__close {
    align-self: baseline;
    cursor: pointer;
  }

  &__btn-icon {
    padding-top: 2px;
  }
}
