.wrapper-form-bitSize{
  width: '30%',
}

.well-phase {
  &__description {
    color: #ccc;
  }

  &__title {
    color: #fff;
    text-align: center;
    justify-content: center;
    margin-bottom: 0;
    color: $white;
    letter-spacing: 0.03em;
    font-size: 1.7em;
  }
}
