.content-quality-control-page{
  height: 100%;
}

.popover-concepts {
  width: 400px;
  display: flex;
  justify-content: flex-end;
}

.quality__control{
  height: 100%;

  &_container {
    width: 95%;
    height: 100%;
    overflow: auto;
    background: transparent;

    h3 {
      color: #fff;
      font-size: 24px;
    }
  }
  .charts-wrapper{
      padding: 5px;
  }

  &_title{
    font-size: 22px;
  }
  &_header_area {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #fff;
    }

  &_buttons_area{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
  }

}


.btn-go-back {
  margin-left: 10px;
  color: #fff;
  border: none;
  font-size: 20px;
}

.btn-apply-changes{
  background-color: #1E97C2;
  color: #fff;
  border: 1px solid #1E97C2;
  margin-right: 10px;
  &:hover{
    background-color: #23b0e4;
    color: #eee;
  }
}

.ant-menu {
  background-color: #212438;
  border: 1px solid #323752;
  color: #fff;

  &-item{
    background-color: transparent;
    margin: 0 5px;
    border-radius: 5px;
    color: #fff;
  }
  &-item-active {
    background-color: #323752;
    color: #fff;
  }

}
