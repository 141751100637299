.overlay-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000,.7);
  z-index: 800;
  .overlay-loading-wrapper {
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    color: #fff;
    background: rgba(0, 0, 0, 0);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.overlay {
  & .title {
    font-family: sans-serif;
    font-size: 16px;
    color: #fff;
  }
  &-show {
    display: block;
  }
  &-hide {
    display: none;
  }
  &-load {
    width: 100px;
    height: 50px;
  }
}
