$white: #fff;

.wrapper-drawer {
  .ant-drawer-header {
    border-bottom: none;
  }

  .ant-drawer-body {
    padding: 0;
    height: 100%;
  }

  .ant-drawer-close {
    top: 20px;
    right: 38px;
    color: #262626;
    font-size: 22px;
  }

  .ant-drawer-right, .ant-drawer-content-wrapper {
    width: 25% !important;
  }
}

.wrapper-settings {
  display: block;
  background-color: $white;
  height: 100%;
}

.wrapper-settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 41px;
  margin-left: 41px;
  margin-top: 32px;
}

.title-settings {
  font-size: 16px;
  color: #000000;
  margin-bottom: 0px;
}

.settings-close {
  height: 20px;
  cursor: pointer;
}

.wrapper-menu {
  display: block;
  height: 100%;

  .ant-tabs {
    height: 100%;
  }

  //Style of the hover
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: none;
  }

  //Style of each tab name
  .ant-tabs-nav .ant-tabs-tab {
    color: #000000;
    opacity: 0.6;
  }

  //Style of each tab name when clicked
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #007BFF;
  }

  //Bar style of each tab name when clicked
  .ant-tabs-ink-bar {
    background-color: #007BFF;
  }

  .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }

  .ant-tabs-tab-prev-icon-target, .ant-tabs-tab-next-icon-target {
    font-size: 20px;
  }
}

.content-tab {
  display: block;
  width: 80%;
  height: 500px;
  margin-left: 41px;
  margin-right: 39px;
  font-family: sans-serif;
  font-size: 14px;
  color: #000000;

}

.box-inputs {
  height: 90%;
}

.select-curve {
  //Style of the select curve
  .ant-select-selection{
    background-color: $white;
    color: #000000;
    width: 100%;
  }

  //Arrow style os the select curve
  .ant-select-arrow {
    color: #000000;
  }
}

.select-xAxis {
  .ant-select-selection{
    background-color: #f9f8f8;
    // color: #d6d4d4;
  }
}

.box-color {
  display: flex;
}

.input-color {
  margin-left: 13px;
}

.select-curve,
.text-line-min,
.text-line-max,
.text-min,
.text-max {
  margin-bottom: 28px;
}

.select-curve, .select-xAxis {
  .form, .ant-select {
    width: 100%;
  }
}

.wrapper_buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.button-ok, .button-apply {
  //Button style
  .ant-btn-primary {
    width: 89px;
  }

  //Button style when clicked
  .ant-btn:focus {
    background-color: #007BFF;
    color: $white;
  }

  //Button style when hover
  .ant-btn:hover, .ant-btn:active, .ant-btn.active {
    background-color: #007BFF;
    color: $white;
  }
}

.button-ok .ant-btn-primary {
  margin-right: 14px;
}

.message-error {
  color: red;
  display: flex;
  margin-top: 5px;
  font-size: 13px;
}
