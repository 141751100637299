$maxWidth: 94%;

.home {
  background-color: $bgDarkPage;
  overflow: hidden;
  position: relative;
  height: 100vh;
  &__modal {
    &-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    & .ant-steps-item-title {
      color: $white !important;
    }
    &-form-group {
      display: flex;
      margin-top: 1rem;
      margin-bottom: 0.25rem;
      align-items: center;
      position: relative;
      > label {
        width: 104px;
        text-align: right;
      }
    }
    .chart-icon {
      width: 40px;
      height: 40px;
    }
    &-group {
      display: flex;
      margin-bottom: 0.6rem;
    }
    &-header {
      justify-content: center;
      align-items: center;
      & > h2 {
        text-align: center;
        padding-bottom: 1rem;
        color: $white;
      }
    }

    &-footer {
      width: 100%;
      padding: 1rem 0;
      display: flex;
      justify-content: flex-end;
    }
    &-content {
      display: flex;
      flex-direction: column;
    }
  }
  &__content {
    display: flex;
    width: calc(100vw - 70px);
    margin-left: 70px;
    height: 100%;
    flex-direction: column;
    align-items: center;
  }
  &__header {
    display: flex;
    width: $maxWidth;
    height: 76px;
    justify-content: space-between;
    &-select-label {
      margin-top: 2px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-name {
      display: inline-flex;
      height: 100%;
      align-items: center;
      font-size: 1.8em;
      color: $white;
    }
    &-select-well {
      & > .ant-select-selection {
        background-color: $bgDark3 !important;
      }
      & .ant-select-arrow .ant-select-arrow-icon > svg {
        fill: $white;
      }
    }
    &-actions {
      span {
        margin-right: 4px;
        height: 21px;
      }
      display: inline-flex;
      height: 100%;
      color: $white;
      align-items: center;
    }
  }
  &__main {
    width: $maxWidth;
    display: flex;
    height: calc(100vh - 80px);
  }
  &__main-content {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  &__cards {
    width: 100%;
    height: 88px;
    display: inline-flex;
    justify-content: space-between;
  }
  &__charts {
    background: transparent;
    border-radius: 8px;
    height: 100%;
    background: $bgDark4;
    margin-top: 20px;
  }
}

.ant-checkbox-wrapper {
  color: $white;
}

.match-curves-label {
  margin-right: 6px;
  width: 184px;
  display: flex;
  align-items: center;
}

.match-relative-label {
  margin-right: 6px;
  width: 80px;
  display: flex;
  align-items: center;
}

.relative-curve {
  margin-top: 1rem;
}

.label-curve {
  text-align: left;
}

.group-reference-curve .ant-select{
  width: 100%;
}

.group-reference-curve .ant-select-selection {
  background-color: $white;
  color: rgba(0, 0, 0, 0.65);
}

.btn-upload, .btn-statistic {
  font-size: 16px;
  border: 1px solid $grayLight3;
  background: $bgDark3;
  color: $white;
  padding: 0.21rem 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  outline: none;
  justify-content: center;
  align-items: center;
  display: flex;
  img {
    margin-right: 4px;
  }
  &:hover {
    color: none;
    border-color: none;
    background-color: none;
  }
}

.btn-statistic {
  img {
    margin-left: 4px;
  }
}

.btn-upload-csv {
  width: 127px;
  height: 32px;
  background-color: #212438;
  border: 1px solid $white;
  font-size: 16px;
  color: $white;
  padding: 0.21rem 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  &:hover {
    color: $colorPictionBlue;
    border-color: $colorPictionBlue;
    background-color: $bgDark3;
  }
}

button.ant-btn.ant-dropdown-trigger.btn-upload-csv.btn-export-well > span + span {
  margin-left: 1px;
  margin-top: 1px;
}

.overlay-export-well {
  ul, li {
    background-color: #fafafa;

    li:hover {
      background-color: #e6f7ff;
    }
  }
}

button.ant-btn.btn-statistic {
  & > span {
    height: 22px;
    margin-top: 1px;
  }
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border: none;
}

.ant-btn:focus {
  background-color: $bgDark3;
  border-color: $white;
  color: $white;
}

.ant-btn:hover, .ant-btn:active, .ant-btn.active {
  background-color: $bgDark3;
  border-color: $colorPictionBlue;
  color: $colorPictionBlue;
}

.ant-menu-root.ant-menu-vertical, .ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right, .ant-menu-root.ant-menu-inline {
  border-radius: 6px;
}

.cross-plot-toggle {
  display: flex;
}

// steps
.steps-content {
  margin-top: 16px;
  border-radius: 6px;
  min-height: 200px;
  justify-content: center;
  display: flex;
}

.steps-action {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.8);
}

.ant-steps .ant-steps-horizontal .ant-steps-label-horizontal {
  width: 592px;
}

.ant-tooltip {
  z-index: 900 !important;
}

// Step - Confliting data
.conflicting-data-title{
  position: relative;
  color: $colorGallery;
  font-size: 20px;
  line-height: 25px;
  padding-top: 10px;
  font-weight: 500;
  width: 100%;
}

.conflicting-data{
  &__description{
    position: relative;
    display: inline-block;
    padding-right: 16px;
    color: $colorGallery;
    font-size: 16px;
    line-height: 16px;
  }

  &__las-name{
    color:#1890ff;
  }

  $inputColor: #8e8d8d;

  &__well-name {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    align-items: center;
    position: relative;
    justify-content: center;

    label{
      color: $white;
      text-align: left;
    }
    input{
      width: 80%;
      margin-left: 10px;
    }

    &--disable{
      label{
        color: $inputColor;
      }
      input[disabled] {
        background-color: $inputColor;
        border-color: $inputColor;

        &:hover{
          border-color: $inputColor;
        }
      }
    }
  }
}

.form-radio{
  margin-top: 15px;
  &__option{
    position: relative;
    display: block;
    padding-right: 16px;
    color: $colorGallery;
    font-size: 16px;
    line-height: 16px;
    margin-top: 25px;
  }
}

// Responsive
@media(max-width: 1420px) {
  .home {
    &-header {
      width: 97%;
    }
    &__content {
      margin-left: 50px;
      width: calc(100vw - 30px);
      background: $bgDarkPage;
    }
  }
}

.tabs-create-well {
  color: $white;
  font-size: 16px;
  padding: 0px 10px;

  &--import {
    width: 550px;
    height: 100%;
  }

  &--preview {
    width: 1200px;
    height: 850px;
  }
}

.bit-size__form-group {
  margin-top: 40px !important;

  .ant-slider{
    width: 85%;
    margin: 0 auto;
  }
}

.bit-size__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bit-size__title {
  font-size: 14px;
  text-align: center;
}

/* Clipboard Bit Size */
.clipboard__container {
  display: flex;
}

.clipboard__wrapper {
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clipboard__input {
  padding: 5px;
  max-width: 70px;
  height: 31px;
  background-color: $bgDark2;
  border: none;
  outline: none;
}

.clipboard__btn {
  background: $bgDark2 !important;
  border: none;
  cursor: pointer;
}

.wrapper-switch-sensor {
  margin-bottom: 10px;
}

.label-switch-sensor {
  color: $white;
}

.input-normalize-gas-data {
  width: 200;
  padding: 4;
  color: '#000';
  background: '#45444f';
  border: 'solid 1px #fff';
  border-radius: 4;
}

.switch-component {
  width: 50px;
  color: $white;
}

.wrapper-select-sensor {
  margin-bottom: 10px;
  margin-top: 10px;
}

.ant-radio-group {
  margin-bottom: 20px;
  margin-left: 15px;
}

.item-radio-group-sensor {
  color: $white;
}

.select-sensor-type {
  width: 200px;
  margin-bottom: 10px;
}

.wrapper-header-modal {
  width: 100%;
  text-align: center;
  justify-content: center;
}

