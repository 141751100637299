.nav {
  @extend %flex;
  @extend %d-column;
  width: 100%;
  font-family: 'Lato';
}

%link-style {
  text-decoration: none;
  font-size: 15px;
  font-family: 'Lato';
  color: $white;
}

.swipe-right {
  &__row {
    @extend %flex;
    @extend %centralize;
  }
  &__nav {
    @extend %flex;
    padding-top: 10px;
    min-width: 40px;
    height: calc(100vh - 170px);
    justify-content: center;
    background-color: #151724;
    position: absolute;
    z-index: 600;
    bottom: 0;
    transition: min-width 0.15s;
    right: -800px;

    @media(max-width: 1421px) {
      right: -785px;
    }
  }
  &__nav-wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  &__button {
    position: absolute;
    left: -50px;
    top: -10px;
    width: 50px;
    height: 50px;
    background-color: #151724;
    color: #fff;
    border: 0;
    outline: none;
    cursor: pointer;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    &-icon {
      margin-left: -5px;
    }
  }
}

.swipe-right--expanded {
  min-width: 700px;
  right: 0;
  justify-content: flex-end;
}

.mirror {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.swipe-right__row {
  justify-content: center;
  align-items: start;
  padding:  0px;
}

//========== TABS
.wrapper-tabs {
  width: 100%;
  background: #2f2e36;
  font-weight: bold;
}

.wrapper-tab-pane {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.wrapper-carbon-chart {
  margin-right: 10px;
  position: relative;
}

.wrapper-normalize {
  position: relative;
}

.main-tab-pane {
  display: flex;
  flex-direction: column;
}

//  settings icons
.settings-carbon-chart {
  position: absolute;
  top: 1%;
  left: 10px;
  z-index: 999;
}

.carbon-container {
  position: relative;
}

.normalize-containe {
  position: relative;
}

.input-min {
  background-color: #2f2e36;
  color: #fff;
  border-radius: 4px;
}

.error-style-normalize {
  color: #ff4141;
  font-Size: 14px;
  margin-left: 23px;
}

.wrapper-max {
  margin-top: 15px;
}

.input-max {
  background-color: #2f2e36;
  color: #fff;
  border-radius: 4px;
}

.wrapper-btn {
  margin-top: 10px;
  margin-bottom: 18px;
}

.settings-normalize-chart {
  position: absolute;
  top: 1%;
  left: 10px;
  z-index: 999;
}

// Ant
.ant-tabs, .ant-list {
  background-color: #151724;
}

.ant-tabs-nav .ant-tabs-tab {
  padding: 16px 40px;
  color: #fff;
}

.ant-tabs-tab-active .ant-tabs-tab {
  color: #fff;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #fff;
}

.ant-tabs-tab-active .ant-tabs-tab {
  color: #fff;
}

.ant-tabs .ant-tabs-top .tabs-ant .ant-tabs-line {
  color: #2f2e36;
}

.ant-tabs-ink-bar .ant-tabs-ink-bar-animated:hover {
  color: #fff;
}

.ant-tabs-tab-active .ant-tabs-tab {
  color: #2f2e36;
}

.ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
  color: #2f2e36;
  background-color: #151724;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: #fff;
}

.ant-tabs-ink-bar {
  background-color: #fff;
}

.swipe-right__button {
  margin-top: 10px;
}

.swipe-right__nav {
  padding-top: 0px;
}

.ant-tabs-bar {
  border-color: #2f2e36;
  background-color: #151724;
}


.wrapper-inside-form {
  width: 500px;
  height: 300px;
}

.header-modal-depth {
  display: flex;
  justify-content: space-between;
  height: 40px;
  color: #fff;
  border-bottom: solid 1px #fff;
  padding-left: 25px;
  padding-right: 15px;
}

.header-title {
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  align-self: left;
}

.form-modal-depth {
  padding: 20px;
  width: 160px;
  display: flex;
  flex-direction: column;
}

.form-modal-depth label {
  font-weight: bold;
  font-size: 12px;
}

.field-form {
  margin-bottom: 10px;
  background: transparent;
  border: solid 1px #fff;
  width: 100px;
  height: 40px;
  border-radius: 6px;
}

.button-form-depth {
 background: #1890ff;
 border: none;
 width: 140px;
 padding: 10px;
 border-radius: 4px;
 cursor: pointer;
 text-transform: uppercase;
}

.button-form-depth--cancel {
  background: transparent;
  border: none;
  width: 140px;
  padding: 10px;
  border-radius: 4px;
  text-transform: uppercase;
  color: #fff;
  border: solid 1px #fff;
  cursor: pointer;
}

.wraper-button-form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding: 16px;
}

.tab__tab-pane {
  padding-left: 45px;
  padding-right: 45px;
}
