.sf-title-box {
  width: 160px;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-top: 16px;
  gap: 5px;
}

.sf-text-center {
  font-size: 11px;
  text-align: center;
}

.sf-container {
  display: flex;
  height: 12px;
  flex-direction: column;
  text-align: center;
}

.sf-values {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
}
