$white: #fff;

.cutoff-plot {
  flex-flow: nowrap;
  flex-direction: column;
  width: 100%;
  .ant-spin {
    margin: 10px 0;
    .ant-spin-text {
      color: $white;
    }
  }
}

.container-spin-update {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.6);
  z-index: 2000;

  .ant-spin.ant-spin-show-text .ant-spin-text {
    font-size: 18px;
  }
}

.scatter-title-curve {
  padding-right: 180px;
  padding-left: 180px;
  display: flex;
  justify-content: space-between;
}

.scatters-cutoff-viewer {
  width: auto;
  font-size: 32px;
  margin-bottom: 1rem;
  border-bottom: 3px solid #007BFF;
}

.scatter-curve {
  display: flex;
  align-items: center;
  color: $white;
  padding-bottom: 11px;

  label {
    margin-right: 10px;
    font-size: 18px;
  }
  &__select {
    width: 160px;
  }
}

.wrapper-slider {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 180px 10px 180px;
}

.wrapper {
  &-settings {
    background: #2f2e36;
    color: #fff;
  }
}

.title-settings {
  color: #fff;
}

.cutoff-view-config .ant-drawer-wrapper-body {
  background-color: #2f2e36;
}


.box-inputs {
  color: #fff;
}

.wrapper-slider-width {
  width: 500px;
  margin-left: 25px;
}

.cutoff-container {
  display: flex;
  justify-content: center;
}

.highcharts-axis-title{
  font-size: 13px;
  font-weight: bold;
}

.wrapper-button-cutoff {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.toggle-crossplot-cutoff {
  display: flex;
  justify-content: center;
  align-items: center;
  position: inherit;
  top: 15px;
  right: 4px;
  background-color: #45444f;
  border-color: #45444f;

  &:hover,
  &:active,
  &:focus {
    background-color: #45444f;
    border-color: #45444f;
    color: $white;
  }
}

.wrapper-input-cutoffs-guidelines {
  display: flex;
  flex-direction: column;
  background-color: #373740;
}

.wrapper-input-filters-guidelines {
  width: 100%;
  padding-bottom: 8px;
  padding-top: 8px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.input-cutoff {
  background: transparent;
  color: #fff;
  border-radius: 4px;
  width: 120px;
  margin-bottom: 0.5rem;
  height: 41px;
  font-weight: normal;
  font-size: 16px;
  padding: 10px;
}

.content-inputs-guidelines {
  display: flex;
  margin-top: 20px;
}

.wrapper-scatter-curve {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 154px;
  padding-left: 20px;
}

.general-container {
  display: flex;
  justify-content: center;
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  width: 200 !important;
  opacity: 0.1 !important;
}

