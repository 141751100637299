.scatters-ethene {
  background: #2f2e36;
  height: 100%;
  overflow: auto;
  width: 100%;
}

.scatter-header-ethene {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 45px 0px 61px 66px;
}

.scatter-info-ethene {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.scatter-title-ethene {
  width: 300px;
  color: #fff;
  font-size: 26px;
  margin-bottom: 0;
  font-weight: bold;
  border-bottom: 3px solid #7959fc;
}

.scatters-ethene-range {
  color: #fff;
  font-size: 22px;
}

.ethene-container {
  display: flex;
  justify-content: center;
}

.wrapper-button-ethene {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.toggle-crossplot-ethene {
  display: flex;
  justify-content: center;
  align-items: center;
  position: inherit;
  top: 15px;
  right: 4px;
  background-color: #45444f;
  border-color: #45444f;

  &:hover,
  &:active,
  &:focus {
    background-color: #45444f;
    border-color: #45444f;
    color: #fff;
  }
}

.wrapper-form-ethene {
  padding: 0px 24px 0px 24px;
}

.wrapper-fields-ethene {
  display: flex;
  align-items: center;
  padding-top: 24px;
}

.labels-ethene {
  width: 50px;
}

.wrapper-error-ethene {
  margin-left: 45px;
}

.wrapper-ethene-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 24px 24px 0px 24px;
}

.ethene-btn-cancel {
  margin-right: 8px;
  .ant-btn:hover, .ant-btn:active, .ant-btn.active {
    background-color: #fff;
  }
}

.ethene-btn-apply {
  margin-left: 8px;
}

.settings-ethene-title {
  padding: 16px 24px 16px 24px;
  font-family: 'Poppins';
  color: #000000;
  font-size: 16px;
  border-bottom: solid 1px #E8E8E8;
}
