.well-phase__btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.well-phase__form-group {
  justify-content: center;
}

.form-well-phase {
  width: 30%;
  display: flex;
  padding-top: 1%;
  flex-direction: column;

  &__label {
    color: #fff;
    text-align: left;
  }

  &__input {
    width: 70%;
  }

  &__button-wrapper{
    margin-top: 1.1rem;
  }

  &__button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
}

.phase-title{
  width: 20%;
  display: flex;
  flex-direction: column;

  &__text{
    padding-top: 10px;
  }
}

form .ant-select, form .ant-cascader-picker {
  width: 70%;
}

.ant-select-auto-complete.ant-select .ant-select-selection--single {
  background-color: white;
  color: black;
}

.error-style-well-phases{
  position: absolute;
  margin-top: 56px;
  color: #ff4141;
}
