.title-row {
  height: auto;
  justify-items: center;
  font-family: $fontPoppins;
  font-size: 16px;
  font-weight: 500;
  color: $white2;
  margin-bottom: 0;
}

.sync-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.sync-icon {
  font-size: 36px;
  text-align: center;
}

.sync-icon-container {
  display: flex;
  justify-content: center;
}

.file-sync {
  font-size: 22px;
  margin-right: 10px;
  margin-top: 10px;
}

.wellName {
  flex: 2;
  margin-top: 10px;
  margin-left: 5px;
}

.sync-modal-title {
  font-size: 20px;
  font-weight: bold;
}