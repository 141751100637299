$white: #fff;
$white2: #F5F5F5;
$white3: #FCFCFC;
$white4: #efefef;
$dark1: #2F2E36;
$dark2: #43414D;
$dark3: #4A4250;
$dark4: #484550;
$dark5: #2f2d35;
$dark6: #111C28;
$bgDarkPage: #1d1c22;
$bgDark2: #1d1c1c;
$bgDark3: #2e2e36;

$bgDark4: #202438;

$bgCardBlack: #2f2e35;
$cardValueColor: #24ff24;

$gray: #616D72;
$gray2: #D9D9D9;
$gray3: #808080;
$gray4: #bfbfbf;
$gray5: #7D7D7D;
$grayLight: #2f2e36;
$grayLight2: #414047;
$grayLight3: #cccccc;

/** 
* Colors Variables
*/

$blue: #007bff;
$blue2: #2b4acb;
$colorPictionBlue: #44C3F2;
$colorCuriousBlue: #27A9D9;
$colorDenim: #108FBE;

$colorMineShaft: #262626;

$colorAlabaster: #FCFCFC;
$colorWildSand: #F5F5F5;
$colorGallery: #EDEDED;

$colorRollingStone: #737C80;
$colorNevada: #616D72;
$colorShuttleGray: #526066;

$red: #dc3545;
$red2: #ff0000;


//Fonts
$fontPoppins: 'Poppins';
$fontRoboto: 'Roboto';