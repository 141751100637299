.popover {
  .ant-popover-title {
    background: #2F2E36;
    padding: 5px 16px;
    border-bottom: 1px solid #434343;
    border-radius: 2px 2px 0px 0px;
    color: $white;
  }

  .ant-popover-inner-content {
    background: $grayLight;
    border-radius: 0px, 0px, 2px, 2px;
    padding: 12px 16px;
    color: #BFBFBF;
  }

  .ant-popover-content {
    box-shadow: 2px 2px 5px 1px rgba($color: #000000, $alpha: 0.4);
  }

  .ant-popover-arrow {
    border-color: #2F2E36 !important;
    right: 2px;
    border-width: 7px;
    box-shadow:  3px -3px 7px rgba(0, 0, 0, 0.4) !important;
  }
}

.content-popover {
  p {
    font-size: 14px;
  }

  div {
    width: fit-content;
    color: #177DDC;
    text-decoration: underline;
    cursor: pointer;
  }
}

.help-popover {
  cursor: pointer;
  margin-left: 20px;
}

.wrapper-significance {
  width: 830px;
  display: flex;
  justify-content: center;
}