.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
  transition: 0.3s;
  border-radius: 6px;
  display: inline-flex;

  &__container {
    width: 100%;
    display: inline-flex;
  }

  &__description {
    width: 36%;
    font-size: 16px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $white;
    flex-direction: column;
    & > p {
      text-align: center;
      line-height: 18px;
    }
  }

  &__value {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.2);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 64%;
    color: $cardValueColor;
    background-color: $bgCardBlack;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    font-size: 24px;
  }

  &--extra-mini {
    display: inline-flex;
    width: 140px;
    height: 50px;
    & .card__value {
      font-size: 18px;
    }
  }

  &--mini {
    display: inline-flex;
    width: 215px;
    height: 62px;
  }

  &--medium {
    display: inline-flex;
    width: 150px;
    height: 115px;
  }

  &--large {
    display: inline-flex;
    width: 320px;
    height: 90px;
  }

  // Bit Depth
  &__bit-depth {
    flex-direction: column;
    background-color: $bgCardBlack;
    align-items: center;
    &-title {
      width: 100%;
      text-align: center;
      font-size: 16px;
      color: $white;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-unit {
      color: #ccc;
      font-size: 1.2rem;
    }
    &-value {
      color: $cardValueColor;
      font-size: 30px;
    }
  }

  &__medium {
    flex-direction: column;
    background-color: $bgCardBlack;
    &-title {
      width: 100%;
      text-align: center;
      font-size: 1.3em;
      color: $white;
      height: 62px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-unit {
      color: #ccc;
      font-size: 0.9em;
    }
    &-value {
      width: 100%;
      text-align: center;
      color: $cardValueColor;
      font-size: 30px;
    }
  }
  &__total-gas {
    & > .card__medium-title {
      height: 70px;
    }
  }
  &__lag-time {
    & > .card__medium-title {
      margin-top: 0.5rem;
    }
  }
}

.hole {
  background: linear-gradient(144deg, #ec6631, #d98a2e);
}

.carbon, .wrapper-carbon {
  width: 42%;
  background: linear-gradient(144deg, #24a233, #2cbd96);
  font-size: 19px;
}

.api, .gor {
  background: linear-gradient(144deg, #1f82e9, #58cbfb);
}

.ethene, .propene {
  background: linear-gradient(144deg, #aa58fd, #7959fc);
}

