.title-row {
  height: auto;
  justify-items: center;
  font-family: $fontPoppins;
  font-size: 16px;
  font-weight: 500;
  color: $white2;
  margin-bottom: 0;
}

.form-scale {
  @extend %flex;
  justify-content: center;
  // align-items: center;
  background-color: transparent;
  & span {
    font-size: 14px;
    color: $gray3;
    cursor: pointer;
  }
  &__subtitle {
    font-size: 1em;
    font-weight: normal;
  }
}

.form-scale__subtitle {
  color: $white2;
  font-family: $fontPoppins;
  font-weight: 600;
  font-size: 18px;
}

.form-scale__wrapper-inputs {
  @extend %flex;
  flex-direction: column;
  margin-top: 28px;
}

.form-scale__wrapper-input {
  @extend %flex-column;
}

.form-scale__box-input {
  @extend %flex-column;
}

.form-scale__label {
  margin-bottom: 7px;
  color: $white2;
  font-weight: bold;
  font-size: 16px;
  font-family: $fontRoboto;
}

.form-scale__input {
  width: 352px;
  height: 35px;
  border-radius: 6px;
  border: 1px solid #525775;
  box-sizing: border-box;
  outline: none;
  background-color: transparent;
  padding: 15px 10px;
  color: #A3A8C7;
  font-family: $fontRoboto;
  font-weight: 500;
  font-size: 14px;
  opacity: 1;
}

.form-scale__wrapper-color {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;
}

.form-scale__tooltip-color {
  position: relative;
  width: 0;
  right: 300px;
}

.form-scale__close-tooltip {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 70px;
  left: 0;
}

.form-scale__picker {
  display: flex;
  justify-content: flex-end;
  width: 330px;
  margin: 15px 0;
  border-radius: 4px;
  position: absolute;
  left: 218px;
}

.form-scale__picker::before {
  border: 10px solid transparent;
  border-bottom-color: $white;
  content: '';
  right: 225px;
  bottom: 94px;
  position: absolute;
}

.form-scale__group-buttons {
  @extend %between;
  align-items: center;

  button + button {
    margin-left: 12px;
  }
}

.form-scale__group-save-buttons {
  display: flex;
}

.form-scale__restore-button {
  background: $red;
  border-radius: 5px;
  padding: 6px 9px;
  font-size: 0.9em;
  color: $white2;
  font-size: 14px;
  font-weight: 600;
  font-family: $fontPoppins;
}

.form-scale__ok-button {
  @extend .base-button;
  width: 50px;
}

.form-scale__save-button {
  @extend .base-button;
  width: 89px;
}

.form-scale__cancel-button {
  @extend .form-scale__restore-button;
  margin-right: 16px;
}

.base-button {
  height: 32px;
  background: $blue;
  padding: 6px 9px;
  font-size: 0.9em;
  border: none;
  border-radius: 5px;
  color: $white2;
  font-size: 14px;
  font-weight: 600;
  font-family: $fontPoppins;
  cursor: pointer;
}

.form-scale__restore-button,
.form-scale__save-button {
  display: block;
  border: none;
  border-radius: 4px;
  color: $white;
  cursor: pointer;
}

.form-scale__info-modal {
  display: flex;
  margin-bottom: 16px;
}

.form-scale__warning-image {
  display: flex;
  align-items: flex-start;
  margin-right: 14px;
}

.form-scale__title-confirm {
  font-family: $fontPoppins;
  font-weight: 600;
  color: $white3;
}

.form-scale__text-confirm {
  font-family: $fontPoppins;
  font-weight: 500;
  font-size: 14px;
  color: $white3;
}

.form-scale__weight {
  font-weight: 600;
}

.form-scale__wrapper-btn-confirm {
  display: flex;
  justify-content: flex-end;
}
