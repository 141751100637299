.molar-analysis {
  background: transparent;
  height: 100vh;
  width: 100%;
  overflow: auto;

  >div {
    padding: 32px 64px 32px 64px;
  }
}

.header-molar-analysis {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 410px;

  h1 {
    font-size: 32px;
  }
}

.actions-molar-analysis {
  display: flex;
  align-items: center;
  gap: 10px;
}

.zone-icon-svg {
  height: 22px;
}

.settings-outline-icon {
  font-size: 21px;
}

.chart-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.wrapper-card-info-zones {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.selected-zones {
  // & .ant-drawer-content {
  //   color: #fff;
  // }
  & .ant-drawer-body {
    padding: 0;
  }
  & .ant-divider, .ant-divider-vertical {
    margin: 0;
  }
}

.toggle-crossplot-molar-analysis {
  display: flex;
  justify-content: center;
  align-items: center;
  position: inherit;
  top: 15px;
  right: 4px;
  background-color: #EDEDED;
  border-color: #EDEDED;

  &:active,
  &:focus {
    background-color: #EDEDED;
    border-color: #EDEDED;
    color: #50515C;
  }
}

.wrapper-drawer-molar-analysis {
  background: #333333;
  width: 100%;
}

.container-molar-analysis {
  background-color: #333333;
  width: 100%;
}

.wrapper-content-drawer {
  width: 100%;
  height: 100%;
}

.wrapper_buttons-molar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 80px;
}

.content-tab-molar{
  display: block;
  width: 80%;
  height: 500px;
  margin-left: 41px;
  margin-right: 39px;
  font-family: sans-serif;
  font-size: 14px;
  color: #fff;
}

.label-settings-molar-chart {
  display: block;
  color: #fff;
  font-weight: bold;
  padding-bottom: 10px;
}

.input-settings-molar-chart {
  width: 220px;
  padding: 18px;
  color: #fff;
}

.button-cancel-molar-chart {
  background: transparent;
  border: solid 1px #fff;
  color: #fff;
  padding: 10px;
  width: 100px;
  height: 40px;
  &:hover {
    background: transparent;
    border: solid 1px #fff;
    color: #fff;
  }
  cursor: pointer;
}

.button-apply-molar-chart {
  padding: 10px;
  width: 100px;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
}

.wrapper-settings-input-molar {
  margin-top: 20px;
}

.wrapper_buttons-molar {
  margin-top: 400px;
}

.box-input-molar {
  margin-bottom: 28px;
}

.curves-molar-form {
  padding-bottom: 10px;
  list-style: none;
}

.list-curve-form-molar {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100px;
  list-style: none;
}

.list-checkbox-curve-molar {
  display: block;
  margin-bottom: 10px;
}

.box-input-molar-xaxis {
  height: 200px;
}

.tab-settings-molar-chart .ant-tabs-tab-active .ant-tabs-tab {
  color: #fff;
}

.wrapper-menu .ant-tabs-nav .ant-tabs-tab {
  color: #fff;
}

.wrapper-menu .ant-tabs-nav .ant-tabs-nav-wrap,
.wrapper-menu .ant-tabs-nav-wrap {
  justify-content: flex-start;
}

.wrapper-menu .ant-tabs-nav .ant-tabs-tab-active {
  color: #177DDC;
}

.wrapper-menu .ant-tabs-nav .ant-tabs-tab:hover {
  color: #177DDC;
}

.select-scale-type {
  .ant-select-selection {
    background-color: transparent;
  }
  .ant-select {
    width: 220px;
  }
}

