%d-column {
  flex-direction: column;
}

%flex-column {
  display: flex;
  flex-direction: column;
}

%inline {
  display: inline-flex;
}

%flex{
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

%i-flex {
  display: inline-flex;
}

%centralize {
  justify-content: center;
  align-items: center;
}

%container {
  @extend %flex;
  width: 100%;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%between {
  display: flex;
  justify-content: space-between;
}
