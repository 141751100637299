.container-item {
  display: none
}

.rop-title-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: $bgDark4;
  z-index: 30;
  height: 180px;
}

.total-gas-header {
  $self: &;

  display: flex;
  z-index: 20;
  flex-direction: column;
  font-size: 12px;
  width: 244px;
  background-color: $bgDark4;
  position: relative;

  &__title {
    text-align: center;
    display: flex;
    width: 95px;
    top: 15px;
    left: 50px;
    justify-content: center;
    position: relative;
  }
  &__values {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #333;
  }

  &__checkbox {
    display: none;
    position: absolute;
    left: 184px;
    top: 10px;
  }

  &:hover {
    display: flex;
    > #{$self}__checkbox {
      display: flex;
    }
  }
}

.label-total-gas {
  display: none;
  position: absolute;
  left: 184px;
  top: 0px;
  &:hover {
    display: flex;
    > .total-gas__checkbox {
        display: flex;
    }
  }
}

.label-gqr {
  display: none;
  position: absolute;
  left: 184px;
  top: 3px;
  &:hover {
    display: flex;
    > .total-gqr__checkbox {
        display: flex;
    }
  }
}

.title-gas {
  margin-left: 40px;
}

.isotope-header {
  width: 180px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: 180px;
  justify-content: flex-end;
}

.rop-wrapper {
  width: 198px;
  display: 'inline-flex';
}

.wob-title,
.rop-title,
.character-title {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  border-bottom: 1px solid #ccc;
}

.rop-title {
  display: flex;
}

.balance-ratio {
  width: 164px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 4px;
}

.character-title {
  color: #26b5e8;
  border-color: #26b5e8;
}

.title-rop {
  // margin-left: 30px;
  text-align: center;
}

.target-rop {
  display: flex;
  flex-wrap: nowrap;
  width: 310px;
  height: 20px;
  justify-content: center;
  position: relative;
}

.show-checkbox-rop {
  display: none;
  position: absolute;
  left: 102px;
  top: 0px;
}

.target-rop:hover > .show-checkbox-rop {
  position: absolute;
  display: flex;
}
