.selected-zones {
    font-family: sans-serif;
    &-title {
        color: #fff;
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 10px;
        margin-left: 30px;
        font-size: 20px;
    }
}

.wrapper-zones {
    &-title {
        color: #fff;
        font-weight: bold;
        font-size: 16px;
        margin-top: 20px;
        margin-left: 30px;
    }
    &-container {
        text-align: left;
        justify-content: flex-start;
        align-items: flex-start;
        max-height: 270px;
        overflow-y: auto;
    }
    &-name-container {
        margin-top: 10px;
        margin-left: 30px;
        margin-bottom: 20px;
    }
    &-zones-name {
        padding: 10px;
    }
}

.btn-add-baseline {
    background: #50515C;
    color: #fff;
    width: 240px;
    height: 45px;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 10px;
}

.add-baseline-section {
    display: flex;
    padding-top: 20px;
    padding-left: 10px;
    justify-content: center;
    height: 200px;
}

.container-actions {
    display: flex;
    padding-top: 20px;
    padding-left: 10px;
    justify-content: space-around;
    & .btn-apply {
        width: 110px;
        height: 36px;
        margin-right: 10px;
        &:hover {
            color: #fff;
        }
    }
    & .btn-cancel {
        // width: 110px;
        // height: 36px;
        background: transparent;
        color: #fff;
        &:hover {
        //     background: #fe3148;
            border: 1px solid #fff;
        }
    }
}

.zone-list-item {
    display: flex;
    align-items: center;
}

.zone-circle {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin-left: 10px;
    margin-right: 10px;
}

.flex {
    display: flex;
}

.compare-text {
    margin-left: 10px;
}

.compare-section {
    padding-top: 20px;
    padding-bottom: 20px;
}

.disabled-label {
    color: #ccc;
}