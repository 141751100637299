.msg-critical {
  width: 626px;
  height: 150px;
  background: $dark4;
  margin-bottom: 10px;
}

.msg-critical-content {
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;

  &__title {
    font-family: $fontRoboto;
    font-weight: 500;
    font-size: 16px;
    color: $white2;
  }

  &__information {
    font-size: 14px;
    color: $gray5;
  }

  &__icon-close {
    padding-right: 8px;
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

}

.msg-content-btns {
  margin-top: 18px;
}

