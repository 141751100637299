.container-apply-all {
  background-color: #262626;
  width: 100%;
  padding: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;

    &__wrapper {
      display: flex;
      align-items: center;

      &__title {
        color: $white;
        font-weight: 600;
        font-size: 24px;
        margin-left: 15px;
      }
    }
  }

  &__contents {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &__text {
      color: $gray4;
      font-size: 16px;
      margin-left: 6px;
    }
  }

  &__form {
    margin-top: 20px;
  }
}