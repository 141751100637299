.fullscreen {
  width: 100%;
  display: flex;
  height: 100%;
}

.fullscreen-enabled{
  padding: 24px;
}

.wrapper-full-screen {
  position: absolute;
  z-index: 1;
}

.fullscreen:hover .wrapper-full-screen {
  display: flex;
  pointer-events: auto;
}

.wrapper-full-screen__btn {
  margin-top: 28px;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  padding: 4px;
  background-color: #151724;
  border-radius: 6px;
  border: 1px solid #323752;
  outline: none;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    background-color: #202438;
  }

  &:active {
    background-color: #202438;
  }
}

.full-screen-icon {
  color: #fff;
  font-size: 26px;
}
