.content-alert-warning {
  width: 100%;

  .ant-alert-warning {
    background-color: #352f1b;
    border: 1px solid #ffe58f;
  }

  // Title
  .ant-alert-with-description .ant-alert-message {
    font-size: 16px;
    font-family: 'Roboto';
    color: #F5F5F5;
  }

  // Description
  .ant-alert-with-description .ant-alert-description {
    font-size: 14px;
    font-family: 'Roboto';
    color: #BFBFBF;
  }

  // Close
  .ant-alert-close-icon .anticon-close {
    color: #7D7D7D;
  }
}
