.container {
  width: 95%;
  height: 100%;
  overflow: auto;
  background: #151724;
  h3 {
    color: #fff;
    font-size: 24px;
  }
}

.popover-concepts {
  width: 400px;
  display: flex;
  justify-content: flex-end;
}

.alert-statistics-concepts {
  width: 570px;
}

.tab-statistic-page {
  width: 100%;
}

.tab-statistic-page .ant-tabs-nav .ant-tabs-tab-active {
  font-family: 'Roboto';
}

.tab-statistic-page .ant-tabs-nav .ant-tabs-tab {
  font-family: 'Roboto';
}

.tab-panel-statistics {
  width: 100%;
}

.content-statistic-page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-go-back-statistics {
  align-self: flex-start;
}

.custom-tabs {
  width: 100%;
  color: #f5faff;
}

.custom-tabs .ant-tabs-tab {
  margin-right: 2px;
}

.custom-tabs .ant-tabs-ink-bar {
  background-color: #4f62c5;
  height: 2px;
}

.custom-tabs .ant-tabs-tab-btn:focus {
  color: #4f62c5;
  outline: none;
}
