@media (max-width: 1400px) {
  .charts-wrapper {
    overflow: auto;
  }
  .card--mini {
    width: 13rem;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .card--mini {
    width: 10rem;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .card--mini {
    width: 12.8rem;
  }
}
