.total-gas-container {
  margin-left: 0.5rem !important;
}

.total-gas-labels {
  position: relative;
  width: 100%;
  height: 20px;
  display: flex;
}

.total-gas {
  position: relative;
  width: 100%;
  height: 20px;
  display: flex;
  &-label-0,
  &-label-1,
  &-label-2,
  &-label-3,
  &-label-4,
  &-label-5,
  &-label-6,
  &-label-7,
  &-label-8,
  &-label-9 {
    display: inline-flex;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
  }
}