.modal-scale__header {
  @extend %between;
  align-items: center;
  height: 64px;
  padding: 0px 20px 4px;
}

.modal-scale__close {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.modal-scale__form {
  @extend %flex-column;
  justify-content: space-between;
  // padding: 0px 40px;
  // height: 73vh;
}

.modal-scale__title {
  margin: 0;
  height: 29px;
}
