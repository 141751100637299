.zero-state-msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Roboto';

  &__img {
    width: 50px;
    height: 50px;
  }

  &__text {
    margin-top: 20px;
  }
}

.btn-apply-statistics {
  margin-top: 20px;
}
