

.header-statistics-concepts {
  height: 50px;
  padding: 16px 24px;
  border-bottom: 1px solid #323752;
  background-color: #11121c;

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
  }

  &__close {
    cursor: pointer;
  }
}

.custom-scrollbar-statistics {
  width: 50% !important;
  left: 5px;
}

.contents-statistics-concepts {
  padding: 24px 24px;
  background-color: #11121c;

  &__clock {
    display: flex;
    color: $white3;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
    gap: 10px;
  }

  &__text {
    color: #BFBFBF;
    font-size: 14px;
  }

  &__title {
    display: block;
    color: $white3;
    font-size: 22px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &__subtitle {
    display: block;
    color: $white3;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  &__summary {

    &__title {
      display: block;
      color: $white;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}

.contents-statistics-concepts__menu {
  background-color: #151724 !important;
  width: 280px;
  color: $white;

  .ant-menu-submenu-title {
    padding-left: 40px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: 30px !important;
    display: flex;
    align-items: center;
  }
  .ant-menu-submenu-arrow {
    right: 250px !important;
  }

  &--link {
    width: fit-content;
    background-color: transparent;
    margin-left: 0;
    padding-left: 0;
  
    &:hover {
      color: $white;
    }
  
    .ant-anchor-ink {
      height: 0;
    }
  
    .ant-anchor-link {
      line-height: inherit;
      padding: 8px 0px;
    }
  
    .ant-anchor-link-title {
      color: $white;
    }
  }

  &--sublink {
    width: auto;
    background-color: #141414;

    .ant-anchor-link {
      line-height: inherit;
      padding: 0px 0px 8px 50px;
    }

    .ant-anchor-link-title {
      color: #BFBFBF;
    }
  }

  &--item {
    background-color: transparent !important;
    padding-left: 40px !important;
    margin: 0 !important;
    height: 30px !important;
    display: flex;
    align-items: center;
  }
}

.menu-content-achors {
  a:link, a:visited, a:hover, a:active { color: #fff }
  margin-left: 45px;
}

.menu-content__hasLink-achor{
  color: '#BFBFBF';
}
