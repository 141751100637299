/*---------TOTAL DEPTH------------*/
/* Color of center line */

#total-depth  .highcharts-yaxis-grid path:first-child {
  stroke: #151724;
}

#total-depth .highcharts-yaxis-grid path:first-child + path {
  stroke: #202438;
}

#total-depth {
  display: inline-flex;
  overflow: visible !important;
}
