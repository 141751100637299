.container-alert-statistics {
  .ant-alert-info {
    background-color: transparent;
    border: 1px solid #323752;
    border-radius: 8px;
    color: #D5D5D6;
  }

  .ant-alert-with-description .ant-alert-message {
   color: $white2;
   font-weight: 600; 
  }

  .ant-alert-close-icon .anticon-close {
    color: #7D7D7D;
  }

  .wrapper-description {
    background-color: transparent;

    p {
      padding-bottom: 10px;
    }

    button {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 224px;
      height: 38px;
      border: 1px solid #323752;
      border-radius: 6px;
      background: transparent;
      color: $white;
      padding: 8px 16px;
      cursor: pointer;
    }

    button:hover {
      background-color: #202438;
      border: 1px solid #A9AEC7;
      color: #fff;
    }
  }
}