.container-card-info {
  width: fit-content;
  height: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 2px;

  span {
    color: #A3A8C7;
    font-size: 14px;
  }
}

.header-info {
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-size: 16px;
    font-weight: 600;
    color: #FAFBFF;
  }
}
