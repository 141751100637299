.container-tabs-significance {
  width: 100%;

  .ant-tabs-bar {
    border-color: #323752;
    margin: 0;
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    color: $white !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    border-color: #4F62C5 !important;
    border-bottom: none !important;
    border-radius: 8px;
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    background: transparent;
    color: $white;
    font-weight: 400;
    font-size: 14px;
    border-radius: 8px;
  }

  .ant-tabs .ant-tabs-top-content, .ant-tabs .ant-tabs-bottom-content {
    margin-top: 20px;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  p {
    color: $white;
  }
}

.box-chart-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-chart-form {
  width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #262626;
  padding: 20px;
  margin-bottom: 20px;
}

.wrapper-chart-form {
  width: 500px;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title-chart-form {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}

.wrapper-form-significance {
  margin-top: 20px;
}

.container-modal-significance-modal {
  display: flex;
}

.container-apply-significance {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.box-significance {
  padding: 20px;
  border: 1px solid #323752;
  border-radius: 8px;
  height: fit-content;
}

.box-significance h3 {
  font-size: 20px !important;
}

.container-boxes {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.container-significance-form__toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-input[disabled] {
  color: #525775;
}

.ant-btn[disabled] {
  background-color: #2F334D;
  border-color: #2F334D;
  color: #A3A8C7;
}