.header-significance {
  height: 50px;
  padding: 16px 24px;
  border-bottom: 1px solid #434343;

  div {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 16px;
    }

    img {
      cursor: pointer;
    }
  }
}

.contents-significance {
  padding: 24px;

  > span {
    font-size: 24px;
    font-weight: bold;
  }

  > p {
    margin-top: 15px;
    color: #F5F5F5;
    font-size: 14px;
  }
}

.significance-level {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  span {
    margin-top: 12px;
    font-size: 12px;
  }
}

.how-apply {
  background: #262626;
  padding: 14px;
  border-radius: 5px;

  span {
    font-size: 14px;
    font-weight: bold;
  }

  p {
    margin-top: 10px;
    font-size: 14px;
    color: #BFBFBF;
  }
}