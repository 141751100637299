.container-significance-form {
  &__label {
    font-size: 16px;
    color: #7D7D7D;
  }

  &__input {
    width: 100%;
    background-color: transparent !important;
    border: 1px solid #525775;
    color: #fff;
    border-radius: 6px;
    margin-top: 8px;
  }

  &__input--error {
    border: 1px solid #D32029;
    color: #D32029;
    font-size: 14px;
    padding: 1px;
  }

  &__button {
    background-color: #233BB6;
    border-color: #233BB6;
    border-radius: 6px;
    color: #fff;
    margin-top: 10px;
  }

  &__button:hover {
    background-color: #233BB6;
    border-color: #fff;
    color: #fff;
  }

  &__confirm {
    margin-left: 12px;
  }
}

.error-significance-level {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-left: 140px;
  text-align: center;

  &__message {
    font-size: 16px;
    color: #D32029;
    margin-left: 6px;
  }
}