@import '../../styles/variables.scss';

.wrapper-preview {
  height: 780px;
  padding: 5px 0px 0px 16px;
  background-color: $grayLight;
  white-space: nowrap;
}

.custom-scrollbar {
  background-color: $white;
  height: 30px;
  opacity: 0.7;
  border-radius: 8px;
  cursor: pointer;
}

.track-vertical {
  position: absolute;
  width: 18px !important;
  right: 2px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
}

.track-horizontal {
  height: 16px !important;
  right: 2px;
  bottom: 2px;
  left: 2px;
  border-radius: 3px;
}
