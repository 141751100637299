.statistic-collapse .ant-collapse {
  font-family: $fontRoboto;
  font-weight: normal;
  background: transparent;
  color: #FDFDFD;
  font-size: 14px;
  border: 1px solid #323752;
  border-radius: 8px;
}

.ant-collapse-content {
  background: #151724;
  color: #FDFDFD;
  border-top: 1px solid #323752;
}

.ant-collapse-content-box {
  color: #FDFDFD !important;
}

.ant-collapse .ant-collapse-item {
  border-bottom: 1px solid #323752;
}

.statistic-collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #FDFDFD;
}

/*  Panel One */
.panel-one {
  width: 100%;
}

.section-checkbox {
  display: 'flex';
  flex-direction: 'column';
  width: 100%;
}

.label-input {
  margin-bottom: 10px;
  margin-top: 16px;
}

.panel-input__input {
  width: 100%;
  margin-bottom: 16px;
}

.section-checkbox__text {
  color: #FDFDFD;
}

.panel-one__btn {
  width: 84px;
  height: 33px;
  margin-top: 18px;
}

.wrapper-btn-icon {
  margin-top: 16px;
}

.panel-range__wrapper {
  width: 340px;
  margin-top: 20px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.panel-range__wrapper-range {
  width: 240px;
}

.panel-range__input {
  width: 70px;
  height: 30px;
  margin-left: 20px;
}

.title-range-panel {
  color: '#7d7d7d';
}

.ant-radio-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-radio-inner {
  border: 2px solid #D1D5DB;
  background-color: transparent;
  width: 16px; 
  height: 16px; 
  position: relative;
}

.ant-radio-inner::after {
  content: ''; 
  background-color: #4F62C5; 
  top: 20%;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-inner:hover {
  border-color: #4F62C5;
}

.ant-radio-group {
  margin-left: 0;
}

.label-input__title,
.label-checkbox__title {
  font-weight: bold;
}

.panel-radio-group {
  margin-top: 10px;
}

.wrapper-ranger {
  display: flex;
  justify-content: space-between;
  align-items: center;
}