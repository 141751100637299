.container-significance-level {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;

    &__title {
      font-size: 16px;
      font-weight: 600;
      color: $white;
    }
  }
}