.charts-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $bgDark4;
}

.chart {
  &__box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
}

.container-total-depth {
  margin-left: 10px;
}

.rop-header-chart {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 180px;
}

.charts-container {
  display: flex;
  position: relative;
  height: 100%;
  // NOTE:
  // This is required to avoid get other event
  // dispatched by other child element
  // this will update the header values
  pointer-events: all;
}

.charts-container__spinner {
  z-index: 100;
  justify-content: center;
  display: flex;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
}

.charts-header {
  display: flex;
  align-items: flex-end;
  &__total-depth,
  &__selected-depth {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    height: 180px;
  }
  &__total-depth {
    width: 108px;
    background-color: $white;
    background-color: $bgDark4;
    justify-content: flex-end;
  }
  &__selected-depth {
    width: 84px;
    justify-content: flex-end;
  }
}

.gas-composition {
  &-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 12px;
    width: 212px;
    z-index: 600;
    height: 180px;
    gap: 4px;
  }
}

.charts-container {
  margin-left: -5px;
  margin-top: 2px;
}

.chromatography-labels {
  position: relative;
  width: 100%;
  display: flex;
}

.chromatography {
  position: relative;
  width: 100%;
  height: 20px;
  display: flex;
  &-label-0,
  &-label-1,
  &-label-2,
  &-label-3,
  &-label-4,
  &-label-5,
  &-label-6,
  &-label-7,
  &-label-8,
  &-label-9 {
    display: inline-flex;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
  }
  &-label-1 {
    left: -4px;
  }
  &-label-2 {
    left: 42px;
  }
  &-label-3 {
    left: 112px;
  }
  &-label-4 {
    left: 180px;
  }
  &-label-5 {
    left: 248px;
  }
  &-label-6 {
    position: absolute;
    left: 268px;
  }
}

.gas-composition {
  &-c1 {
    color: #86229f;
    border-bottom: 1px solid #86229f;
  }
  &-c2 {
    color: #00adf3;
    border-bottom: 1px solid #00adf3;
  }
  &-c3 {
    color: #cf000d;
    border-bottom: 1px solid #cf000d;
  }
  &-c4 {
    color: #22b96c;
    border-bottom: 1px solid #22b96c;
  }
  &-c5 {
    color: #221d1e;
    border-bottom: 1px solid #221d1e;
  }
  &-header {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 212px;
    z-index: 600;
  }
  &__title {
    text-align: center;
  }
  &__values {
    display: flex;
    justify-content: space-between;
  }
}

.charts-container {
  margin-left: -5px;
  margin-top: 2px;
}

.gasComposition {
  &-labels {
    width: 100%;
    display: flex;
    position: relative;
    height: 20px;
    border-bottom: 1px solid #333;
  }
  &-label-1,
  &-label-2,
  &-label-3,
  &-label-4,
  &-label-5 {
    display: inline-flex;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    top: 3px;
  }
  &-label-1 {
    left: 0;
  }
  &-label-2 {
    left: 65px;
  }
  &-label-3 {
    left: 132px;
  }
  &-label-4 {
    left: 194px;
  }
  &-label-5 {
    left: 248px;
  }
}
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: none;
}

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
}

.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.container-checkbox input:checked ~ .checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.container-checkbox .checkmark:after {
  left: 5px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// Gas Ratio
.title-gr {
  justify-content: center;
  position: relative;
}

.target-gr {
  display: flex;
  width: 300px;
  justify-content: center;
}

.show-checkbox-gr {
  display: none;
  padding-left: 10px;
  margin-right: 25px;
}

.target-gr:hover > .show-checkbox-gr {
  display: flex;
  position: absolute;
  right: 4px;
}

// C1, C2, C3
.chromatography-column {
  $self: &;

  &__target {
    display: flex;
    flex-wrap: nowrap;
    width: 100px;
    justify-content: center;

    &:hover {
      > #{$self}__show-checkbox {
        display: flex;
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__show-checkbox {
    display: none;
    padding-left: 0;
    margin-right: 0;
  }
}

//ic4
.title-ic4 {
margin-left: 0;
}
.target-ic4 {
display: flex;
flex-wrap: nowrap;
width: 100px;
justify-content: center;
}
.show-checkbox-ic4 {
display: none;
padding-left: 10px;
margin-right: 25px;
}
.target-ic4:hover > .show-checkbox-ic4 {
  display: flex;
  position: absolute;
  right: -11px;
}

//nc4
.title-nc4 {
margin-left: 0;
}
.target-nc4 {
display: flex;
flex-wrap: nowrap;
width: 100px;
justify-content: center;
}
.show-checkbox-nc4 {
display: none;
padding-left: 10px;
margin-right: 25px;
}
.target-nc4:hover > .show-checkbox-nc4 {
display: flex;
position: absolute;
right: -11px;
}
//iC5
.title-iC5 {
margin-left: 0;
}
.target-iC5 {
display: flex;
flex-wrap: nowrap;
width: 100px;
justify-content: center;
}
.show-checkbox-iC5 {
display: none;
padding-left: 10px;
margin-right: 25px;
}
.target-iC5:hover > .show-checkbox-iC5 {
display: flex;
position: absolute;
right: -11px;
}

//nc5
.title-nc5 {
margin-left: 0;
}
.target-nc5 {
display: flex;
flex-wrap: nowrap;
width: 100px;
justify-content: center;
}
.show-checkbox-nc5 {
display: none;
padding-left: 10px;
margin-right: 25px;
}
.target-nc5:hover > .show-checkbox-nc5 {
  display: flex;
  position: absolute;
  right: -11px;
}

//characterRatio
.title-characterRatio {
min-width: 80px;
margin-left: 28px;
display: flex;
flex-direction: column;
text-align: center;
}

.show-checkbox-characterRatio {
display: none;
margin-left: 5px;
}

//balanceRatio
.title-balanceRatio {
min-width: 80px;
margin-left: 28px;
display: flex;
flex-direction: column;
text-align: center;
}

.target-balanceRatio {
display: flex;
flex-wrap: nowrap;
}

.show-checkbox-balanceRatio {
display: none;
margin-left: 5px;
}

.target-balanceRatio:hover > .show-checkbox-balanceRatio {
display: block;
}

//wetnessRatio
.title-wetnessRatio {
min-width: 80px;
margin-left: 28px;
display: flex;
flex-direction: column;
text-align: center;
}

.target-wetnessRatio {
display: flex;
flex-wrap: nowrap;
}

.show-checkbox-wetnessRatio {
display: none;
margin-left: 5px;
}

.target-wetnessRatio:hover > .show-checkbox-wetnessRatio {
display: block;
}


//SFTitle
.target-SFTitle {
display: flex;
justify-content: center;
flex-wrap: nowrap;
}

.show-checkbox-SFTitle {
display: none;
margin-left: 30px;
}

.target-SFTitle:hover > .show-checkbox-SFTitle{
display: block;
margin-left: 30px;
}


// .highcharts-xaxis-grid .highcharts-grid-line {
//   stroke-width: 1px;
//   stroke: '#333';
// }

// .highcharts-grid-line {
//   stroke-width: 1px;
// }