.content_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  width: 100%;
  max-height: 80vh;
  // padding: 20px;
  background-color: #151724;
  border: none;
  border-radius: 8px;
  overflow: auto;
  border-radius: 16px;
}

.overlay_modal {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  left: 0;
}
