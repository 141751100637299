.btn-zoom {
  &__container {
    position: relative;
  }
  padding: 8px 16px;
  background-color: #151724;
  border-radius: 6px;
  border: 1px solid #323752;
  color: #FDFDFD;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  right: 35px;
  cursor: pointer;
  z-index: 700;
  gap: 8px;
}

.zoom {
  &__container {
    z-index: 800;
  }
  &-visible {
    display: flex;
  }
  &-hidden {
    display: none;
  }
}
